@mixin bp($width, $direction: max) {
  @if $direction == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else {
    @media screen and (max-width: $width) {
      @content;
    }
  }
}

@mixin setBgColorAndHover($baseColor) {
  background-color: $baseColor;
  &:focus,
  &:hover,
  &:active {
    background-color: darken($baseColor, 5%);
  }
}

@mixin setGradientAndHover($color1, $color2) {
  background-image: linear-gradient(0deg, $color1 0%, $color2 100%);
  &:focus,
  &:hover,
  &:active {
    background-image: linear-gradient(
      0deg,
      darken($color1, 5%) 0%,
      darken($color2, 5%) 100%
    );
  }
}
