.main-content {
  height: 100%;
  width: 100%;
  position: relative;
}

.main-wrapper {
  height: 100%;
  display: flex;
}
