@import '../../../styles/colours';

.slider-item {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;

  .media > img,
  .media > video,
  .media > audio {
    border-radius: 8px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 246px;
    height: 152px;
  }

  .creator-avatar {
    position: absolute;
    bottom: 18px;
    left: 10px;
    height: 34px;
    width: 34px;
  }

  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    border-radius: 8px;
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(
      90deg,
      rgba(70, 70, 70, 0.5) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    background-image: -webkit-linear-gradient(
      90deg,
      rgba(70, 70, 70, 0.5) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    background-image: -ms-linear-gradient(
      90deg,
      rgba(70, 70, 70, 0.5) 0%,
      rgba(255, 255, 255, 0) 50%
    );
  }
}

.slider-item:hover .overlay {
  opacity: 1;
}
