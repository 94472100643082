@import '../../../../styles/colours';
@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.header {
  position: fixed;
  width: 100%;
  height: 55px;
  padding: 10px 10px 10px 32px;
  z-index: 155;
  background-color: white;
  border-bottom: 1px solid $outline;
  display: flex;
  justify-content: space-between;

  &__burger {
    display: none;
    position: absolute;
    left: 20px;
    top: 10px;
  }

  &__sidebar-toggle {
    display: none;
  }

  &__logo {
    width: 200px;
    margin-right: 80px;
    height: 100%;
    display: flex;
    align-items: center;

    & > img {
      vertical-align: middle;
    }
  }

  &__menu {
    position: absolute;
    right: 0px;
    top: 0px;
    display: inline-block;
    top: 0px;
    right: 20px;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width: $tablet) {
  .header {
    box-shadow: 0px 0px 10px #00000026;
  }
  .header__burger {
    display: block;
  }
}
