@import './../../../../styles/vars.scss';
@import './../../../../styles/mixins.scss';
@import './../../../../styles/colours';

.content-area {
  & .content {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include bp($tablet) {
      padding: 0px 0px 16px 0px;
    }
  }

  & .header {
    width: 100%;

    &__left {
      display: inline-block;
      width: 80%;
    }

    &__right {
      display: inline-block;
      width: 20%;
    }
  }
}
