@import './../../styles/colours.scss';

@import '../../styles/mixins';
@import '../../styles/vars';

$carousel-height: 153px;
$carousel-width: 255px;

.img-carousel {
  width: 120px;
  height: 80px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: black;
  border-color: unset;

  @media (min-width: 1350px) {
    width: $carousel-width;
    height: $carousel-height;
  }

  > img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 0.95;
    z-index: 3;
  }

  & .img-carousel__nav {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    & .nav-unit {
      height: 3px;
      display: inline-block;
      width: 30px;
      border-radius: 10px;
      margin-right: 10px;
      cursor: pointer;
      vertical-align: middle;
    }

    // .active {
    //   background-color: $blue;
    // }
  }

  &__arrow {
    display: inline-block;
    height: 100%;
    width: 20px;
    top: 0px;
    position: absolute;
    color: $white;
    line-height: $carousel-height;
    font-size: 32px;
  }

  .left-arrow {
    left: 20px;
    transform: rotate(-180deg);
    cursor: pointer;
    vertical-align: middle;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));

    .icon {
      height: 15px;
      position: absolute;
      top: calc(50% - 1rem);
      left: 0;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .right-arrow {
    right: 20px;
    cursor: pointer;
    vertical-align: middle;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));

    .icon {
      height: 15px;
      position: absolute;
      top: calc(50% - 0.9rem);
      left: 0;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
