@import './colours';
@import './vars';

.full-width {
  width: 100%;
  height: 60px;
  color: white;
  border-radius: 10px;
  border: 1px black;

  cursor: pointer;
}

$add-new-btn-size: 40px;

.add-new {
  width: $add-new-btn-size;
  height: $add-new-btn-size;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background: rgb(124, 71, 245);
  background-image: linear-gradient(
    0deg,
    rgba(124, 71, 245, 1) 0%,
    rgba(158, 70, 241, 1) 100%
  );
  font-size: 30px;
  color: $white;
  cursor: pointer;
  padding-bottom: 2px;
}

.darken-button {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  &:focus,
  &:hover,
  &:active {
    background-color: darken($color: $blue, $amount: 15);
  }
}
