@import './colours';

$sm-padding: 5px;
$md-padding: 10px;
$l-padding: 20px;
$xl-padding: 40px;
$xxl-padding: 50px;

$small-font: 14px;

// media points
$desktop: 1200px;
$laptop: 1024px;
$tablet: 1024px;
$tablet_portrait: 600px;

// for gradients
$gradient-deg: 164.13deg;

$xxs-size: 0.8rem;
$xs-size: 1rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

// Layout
$sidebar-width: 250px;
$header-height: 55px;

$box-shadow: 0 4px 20px 0 $box-shadow-color;

$ws-item-border-radius: 8px;
