@import '../../styles/vars';

.menu {
  position: absolute;
  top: calc(#{$header-height} - 5px);
  left: -140px;
  width: 200px;
  background-color: $white;
  text-align: center;
  border-radius: 10px;
  box-shadow: $box-shadow;
  z-index: 100;

  & .menu-items {
    padding: 0px;
    margin: 0px;
  }

  &.xs {
    width: 100px;
    left: 15px;
  }

  &.sm {
    width: 150px;
    left: 10px;
  }
}

.extra-wide {
  width: 290px;
  left: -230px;
}

.menu-scroll {
  max-height: 400px;
  overflow-y: scroll;
}
