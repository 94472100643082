@import './../../../styles/colours.scss';

.gallery-slider {
  max-width: 100%;
  height: 200px;
  overflow-y: visible;
  position: absolute;
  top: 0;
  left: 0;

  &__arrow-right {
    position: absolute;
    height: 100%;
    width: 20px;
    right: 20px;
    vertical-align: middle;
  }

  @media only screen and (max-width: 480px) {
    height: 180px;
  }
}

.hide-when-empty {
  .close {
    display: none;
  }
}
