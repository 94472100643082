@import '../../styles/colours';
@import '../../styles/button';
@import '../../styles/mixins';

.btn {
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  flex: 1;
  font-weight: 800;
  letter-spacing: 1.33px;
  line-height: 10px;
  transform: translateZ(0);
}

.primary {
  @include setBgColorAndHover($dim-gray);
  border-radius: 8px;
  margin-right: 3rem;
  color: $medium-gray;
  padding: 15px;
}

.btn-green {
  @include setBgColorAndHover($confirm);
  color: white;

  &:hover {
    background: darken($confirm, 10%);
  }
}

.btn-red-full {
  @include setBgColorAndHover($decline);
  color: white;
}

.secondary {
  color: $cancel-gray;
  display: inline-block;
  background-color: transparent;
}

.red-text {
  color: $decline;
  background-color: transparent;
}

.btn-red {
  @include setBgColorAndHover(transparent);
  color: $decline;
  box-shadow: inset 0 0 0 2px $decline;

  &:hover {
    background: darken($decline, 10%);
    color: $white;
  }
}

.btn-small {
  @include setBgColorAndHover($blue);
  border-radius: 11px;
  width: 8rem;
  height: 22px;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-left: 1rem;
  text-transform: initial;
  letter-spacing: 0.3px;
  line-height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.wide {
  min-width: 150px;
}

.adaptible {
  min-width: unset;
  width: unset;
}

.active-green {
  @extend .btn-green;
}

.active-red {
  @extend .red-text;
}
