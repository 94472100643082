$white: #ffffff;
$off-white: #f5f7f9;
$dim-white: #f4f6fa;

$red: #ff4a7a;
$light-red: rgba(204, 204, 204, 0.05);

$pink: #f344e4;
$mid-violet: #9646f1;

$bg-color: #f4f6fa;
$black: #291A53; // Defined

$gray: #474747; // Defined
$darker-gray: #291A53; // Font
$light-gray: #fbfcfe;
$lighter-gray: #fafbfb;
$light-dark-gray: #949ea5;
$mid-gray: #456279;
$dim-gray: #f6f6f7;
$deep-gray: #bbc2ca;
$outline: #e6edf4;
$pill-gray: #ebedf0;
$save-gray: #acb3bf;
$cancel-gray: #a5adba;
$sidebar-gray: #95a0ab;
$user-pill-gray: #44566c;
$border-gray: #dbe1e6;
$medium-gray: #acb3bf;
$easy-gray: #95a0ac;
$mud-white: #a0abb7;
$hard-white: #f4f6fa;
$hard-red: #fa3e3e;
$box-shadow-color: #25265e26;

$violet: #9646f1; // Defined
$blue: #7547F7;
$lighterer-blue: #e1e5e9;
$lighter-blue: #afb7bf;
$light-blue: #8996a6;
$deep-blue: #291A53;
$blue-gray: #4b5763;
$dark-blue-gray: #4a5b6d;
$heading-gray: #bbc2ca;

$dark-gray: #3f576e;
$yellow: #ffdd00; // Defined
$light-green: #bfb;

$confirm: #25C465;
$decline: #fc3c82;

$dark-blue: #291A53;

$purple: #7547F7;
$purple-gradient: linear-gradient(
  0deg,
  rgba(124, 71, 245, 1) 0%,
  rgba(158, 70, 241, 1) 100%
);

$purple-grad-start: $pink;
$purple-grad-end: $violet;

$success: $confirm;
$light-success: $pink;

// Derived
$inactive: $red;

$error: $red;

// Priority Colors
$priority-high: $red;
$priority-medium: #7547F7;
$priority-normal: $confirm;
$priority-low: #39c0ff;

$priority-high-outline: lighten($priority-high, 20%);
$priority-medium-outline: lighten($priority-medium, 20%);
$priority-normal-outline: lighten($priority-normal, 20%);
$priority-low-outline: lighten($priority-low, 20%);

// Status Colors
$status-todo: $dark-blue-gray;
$status-in-progress: $priority-medium;
$status-in-review: $blue;

$status-todo-outline: lighten($dark-blue-gray, 20%);
$status-in-progress-outline: lighten($priority-medium, 20%);
$status-in-review-outline: lighten($blue, 20%);
