@import '../../styles/colours';

@mixin icon-color($bg: transparent, $font: white) {
  background-color: $bg;

  i {
    color: $font;
  }
}

@mixin green() {
  @include icon-color($confirm);
}

@mixin red() {
  @include icon-color($inactive);
}

@mixin yellow() {
  @include icon-color(transparent, $yellow);
}

@mixin gray() {
  @include icon-color($dim-gray, $dark-gray);
}

.icon-wrapper {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: $lighter-gray;
  cursor: pointer;
  max-width: none;

  &__icon {
    color: $dark-blue-gray;
    font-size: 25px;
  }
}

.space-right {
  margin-right: 2rem;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.hover {
  &--green:hover {
    @include green;
  }

  &--red:hover {
    @include red;
  }

  &--yellow:hover {
    @include yellow;
  }

  &--gray:hover,
  &--gray:active,
  &--gray:link {
    @include gray;
  }
}

.is-starred {
  background: transparent;
  i {
    color: $yellow;
  }
}

.not-starred {
  background: transparent;
  i {
    color: $dark-gray;
  }
}

.filled {
  &--green {
    @include green;
  }

  &--red {
    @include red;
  }

  &--yellow {
    @include yellow;
  }

  &--gray,
  &--gray:active,
  &--gray:link {
    @include gray;
  }
}

.with-counter {
  width: 70px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  width: 36px;
  height: 36px;
  line-height: 36px;

  i {
    font-size: 20px;
    margin: 0;
  }
}

.with-counter.small {
  width: 60px;
  padding-top: 2px;
}
