@import '../../styles/colours';

.upload-area {
  height: 13rem;
  background-color: $dim-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  outline: none;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon {
      color: darken($color: $dim-white, $amount: 12%);
      margin: 0 auto;
    }

    &__heading {
      color: $deep-blue;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 0;
      margin-top: 0.6rem;
      text-align: center;
    }

    &__paragraph {
      color: $darker-gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-top: 0.2rem;
      text-align: center;
    }
  }

  .upload-area__container__icon {
    color: $darker-gray;
  }

  &__files {
    &__h {
      margin-left: 2rem;
    }

    &__items {
      list-style: none;

      li {
        display: inline;
        margin-right: 0.5rem;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 1rem;
  }
}

.dropzone--isActive {
  background-color: $confirm;
  i {
    color: white;
  }
  &.no-background {
    background-color: $white;
    .btn {
      background-color: $violet;
    }
  }
}

.dropzone {
  outline: 0;
}

.disabled-clicks {
  cursor: not-allowed;
}

.button-only {
  display: flex;
  text-align: center;
}
