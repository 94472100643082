@import '../../styles/colours';

.new-comment {
  display: flex;
  justify-content: left;
  align-items: center;
  border-top: 1px solid $outline;
  padding: 0.5rem 0;

  &.in-gallery {
    padding: 0.5rem 2rem;
  }

  .form-group {
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 0;
    padding-top: 0;

    input {
      padding: 0 0 0 0.6rem;
      font-weight: 400;
      letter-spacing: 0.01rem;

      &::placeholder {
        color: $gray;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 0.5rem 1rem;
    font-size: 14px;
    display: flex;
    align-items: center;

    .form-group {
      height: 40px;
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 0;
      margin-bottom: 0;
      padding-top: 0;

      input::placeholder {
        font-size: 14px;
      }
    }
  }
}
