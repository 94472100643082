@import '../../../../styles/colours';
@import '../../../../styles/mixins';
@import '../../../../styles/vars';

.date-selector {
  position: relative;
  color: $deep-blue;
  display: flex;
  align-items: center;
  border: 1px solid $gray;
  border-radius: 6px;
  padding: 4px 5px 4px 10px;
  cursor: pointer;
  font-size: $small-font;

  @include bp($tablet) {
    width: auto;
  }

  .disabled {
    cursor: not-allowed;
  }

  span {
    font-weight: 400;
    margin-right: 0.3rem;
    color: $deep-blue;
  }

  .icon.icon-close {
    font-size: $small-font;
  }

  &.inactive {
    border: none;
    padding: 4px 0;
  }

  .react-datepicker-wrapper {
    font-weight: 400;
  }

  .react-datepicker__input-container {
    input {
      cursor: pointer;
      color: $dark-blue;
      display: inline-block;
      border-radius: 6px;
      font-family: 'nunitosans', 'Roboto', sans-serif;
      @media only screen and (max-width: 768px) {
        max-width: 90px;
      }
    }
  }

  .react-datepicker__month-container {
    .react-datepicker {
      &__header {
        background-color: unset;
        border-bottom: none;
        .react-datepicker__current-month {
          color: $darker-gray;
          font-size: 15px;
          font-weight: 700;
          line-height: 18px;
        }
      }

      &__day:hover {
        border-radius: 50%;
      }

      &__day-names {
        padding-top: 1rem;
        & > .react-datepicker__day-name {
          color: $mud-white;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
        }
      }

      &__day--selected {
        border-radius: 50%;
        background-color: $violet;
      }

      &__day--today {
        font-weight: unset;
        position: relative;

        &::after {
          content: '\25CF';
          position: absolute;
          top: 0.7em;
          left: calc(50% - 3px);
          width: 1px;
          color: $priority-low;
        }
      }

      &__day--keyboard-selected {
        background-color: $white;
        color: $black;
      }
    }
  }
}
