@import '../../styles/colours';
@import '../../styles/vars';

.checkmark-button {
  height: 22px;
  width: 30px;
  border-radius: 7.7px;
  background-color: $gray;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: $violet;
  }
}

.icon-check-flex {
  color: white;
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
