.dropzone {
  margin-right: 2rem;

  @media only screen and (max-width: 480px) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.no-margin {
  margin: 0px;
}
