@import '../../../styles/colours';

.menu-item {
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
  list-style: none;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid $outline;
  display: flex;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: left;

  > a {
    text-decoration: none;
    color: $deep-blue;
  }

  color: $darker-gray;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;

  > i {
    margin: 0 8px;
    font-size: 18px;
  }

  > span {
    width: 70%;
  }

  i:nth-child(2) {
    width: 15%;
  }

  > span {
    width: 70%;
    margin-left: 0.8rem;
  }

  i:nth-child(2) {
    width: 15%;
  }
  &:last-child {
    border-bottom: none;
  }
}

.icon-color-red {
  color: $red;
}

.icon-color-purple {
  color: $purple;
}

.icon-color-darkgray {
  color: $dark-blue-gray;
}

.icon-check {
  text-align: right;
}
