@import './assets/css/fontello.css';
@import './assets/css/nunitosans.css';
@import './styles/colours';

html {
  min-height: 100vh;
  height: 100vh;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  background-color: $bg-color;
  * {
    font-family: 'nunitosans', 'Roboto', sans-serif;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  height: auto;
  max-width: 100%;
}

.app,
#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100vh;
}

// Remove icon margin
i[class^='icon-']:before,
i[class*=' icon-']:before {
  margin: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-text {
  white-space: break-spaces;
}

#hubspot-messages-iframe-container {
  z-index: 60 !important;
  margin-bottom: 50px !important;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.Toastify__toast--success {
  background: $confirm !important;
}
.ReactModal__Overlay.with-fade {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open.with-fade {
  opacity: 1;
}

.ReactModal__Overlay--before-close.with-fade {
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}
