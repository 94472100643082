@import './../../../styles/colours.scss';
@import '../../../styles/vars';

.form-group {
  position: relative;
  padding-top: 1.5rem;
  margin-bottom: 30px;

  label {
    position: absolute;
    top: 0;
    font-size: $small-font;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-out;
    color: $blue-gray;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 13px;
    text-transform: uppercase;
  }

  input:placeholder-shown + label {
    opacity: 0;
    transform: translateY(1rem);
  }

  input {
    display: block;
    height: 2.5rem;
    padding: 0 0.75rem;
    border-radius: 4px;
    border: 1px solid $lighterer-blue;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 0.75rem;
    color: $darker-gray;
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      color: $gray;
    }
  }

  input:required + .help-text::before {
    content: '*Required';
  }

  input:optional + .help-text::before {
    content: '*Optional';
  }

  input:read-only {
    // border-color: $light-blue !important;
    // color: $mid-gray;
    cursor: not-allowed;
  }

  input[type='checkbox'] + label {
    user-select: none;
  }

  input:focus {
    outline: none;
  }
}

.display-validation {
  input:valid {
    border-color: $success;
    background-image: url("data:image/svg+xml,%3Csvg width='45px' height='34px' viewBox='0 0 45 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-56.000000, -59.000000%29' fill='%235848FB'%3E%3Cpolygon points='70.1468531 85.8671329 97.013986 59 100.58042 62.5664336 70.1468531 93 56 78.8531469 59.5664336 75.2867133'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }

  input:invalid {
    border-color: $error;
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-128.000000, -59.000000%29' fill='%23F44336'%3E%3Cpolygon points='157.848404 61.9920213 145.980053 73.8603723 157.848404 85.7287234 154.856383 88.7207447 142.988032 76.8523936 131.119681 88.7207447 128.12766 85.7287234 139.996011 73.8603723 128.12766 61.9920213 131.119681 59 142.988032 70.8683511 154.856383 59'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }

  input:invalid:focus {
    border-color: $error;
  }

  input:invalid + .help-text {
    color: $error;
  }

  input[type='email']:invalid + .help-text::before {
    content: 'You must enter a valid email.';
  }

  input:out-of-range + .help-text::before {
    content: 'Out of range';
  }

  &__invalid {
    &:invalid {
      border-color: $error;
      background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-128.000000, -59.000000%29' fill='%23F44336'%3E%3Cpolygon points='157.848404 61.9920213 145.980053 73.8603723 157.848404 85.7287234 154.856383 88.7207447 142.988032 76.8523936 131.119681 88.7207447 128.12766 85.7287234 139.996011 73.8603723 128.12766 61.9920213 131.119681 59 142.988032 70.8683511 154.856383 59'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  &__error {
    color: $error;
    font-size: 10px;
    position: absolute;
    left: 5px;
    bottom: -25px;

    &.compact-label {
      bottom: -10px;
    }
  }
}

.validated {
  &__invalid {
    input {
      border-color: $error;
      background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-128.000000, -59.000000%29' fill='%23F44336'%3E%3Cpolygon points='157.848404 61.9920213 145.980053 73.8603723 157.848404 85.7287234 154.856383 88.7207447 142.988032 76.8523936 131.119681 88.7207447 128.12766 85.7287234 139.996011 73.8603723 128.12766 61.9920213 131.119681 59 142.988032 70.8683511 154.856383 59'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  &__valid {
    input {
      border-color: $success;
      background-image: url("data:image/svg+xml,%3Csvg width='45px' height='34px' viewBox='0 0 45 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-56.000000, -59.000000%29' fill='%235848FB'%3E%3Cpolygon points='70.1468531 85.8671329 97.013986 59 100.58042 62.5664336 70.1468531 93 56 78.8531469 59.5664336 75.2867133'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.no-border {
  input {
    border: 0;
    cursor: auto;

    &::placeholder {
      color: $gray;
      font-weight: 400;
    }
  }

  label {
    display: none;
  }
}

.is-edit.form-group {
  padding-top: unset;
}

.is-edit {
  input:focus,
  input:active {
    outline: none;
    background-color: $lighter-gray;
  }
}

.no-label {
  label {
    display: none;
  }
}
