@font-face {
  font-family: 'nunitosans';
  src: local('NunitoSans'),
    url(../font/NunitoSans/NunitoSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'nunitosans';
  font-weight: 600;
  src: local('NunitoSans'),
    url(../font/NunitoSans/NunitoSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'nunitosans';
  font-weight: 700;
  src: local('NunitoSans'),
    url(../font/NunitoSans/NunitoSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'nunitosans';
  font-weight: 800;
  src: local('NunitoSans'),
    url(../font/NunitoSans/NunitoSans-ExtraBold.ttf) format('truetype');
}
