@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?41767211');
  src: url('../font/fontello.eot?41767211#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?41767211') format('woff2'),
       url('../font/fontello.woff?41767211') format('woff'),
       url('../font/fontello.ttf?41767211') format('truetype'),
       url('../font/fontello.svg?41767211#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?41767211#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrowdownright:before { content: '\e800'; } /* '' */
.icon-in-progress:before { content: '\e801'; } /* '' */
.icon-maintenance:before { content: '\e802'; } /* '' */
.icon-arrowright:before { content: '\e803'; } /* '' */
.icon-arrowleft:before { content: '\e804'; } /* '' */
.icon-arrowdown-02:before { content: '\e805'; } /* '' */
.icon-arrowright-02:before { content: '\e806'; } /* '' */
.icon-activity:before { content: '\e807'; } /* '' */
.icon-brush:before { content: '\e808'; } /* '' */
.icon-calendar:before { content: '\e809'; } /* '' */
.icon-carot:before { content: '\e80a'; } /* '' */
.icon-check:before { content: '\e80b'; } /* '' */
.icon-clear:before { content: '\e80c'; } /* '' */
.icon-close:before { content: '\e80d'; } /* '' */
.icon-comment:before { content: '\e80e'; } /* '' */
.icon-complete:before { content: '\e80f'; } /* '' */
.icon-dots:before { content: '\e810'; } /* '' */
.icon-important-round:before { content: '\e811'; } /* '' */
.icon-subtask:before { content: '\e812'; } /* '' */
.icon-lock:before { content: '\e813'; } /* '' */
.icon-close-ws:before { content: '\e814'; } /* '' */
.icon-sub-ws:before { content: '\e815'; } /* '' */
.icon-photo-gallery:before { content: '\e816'; } /* '' */
.icon-photo:before { content: '\e817'; } /* '' */
.icon-plus:before { content: '\e818'; } /* '' */
.icon-task:before { content: '\e819'; } /* '' */
.icon-star:before { content: '\e81a'; } /* '' */
.icon-qr:before { content: '\e81b'; } /* '' */
.icon-text:before { content: '\e81c'; } /* '' */
.icon-video:before { content: '\e81d'; } /* '' */
.icon-workspace:before { content: '\e81e'; } /* '' */
.icon-warning:before { content: '\e81f'; } /* '' */
.icon-zoom:before { content: '\e820'; } /* '' */
.icon-time:before { content: '\e821'; } /* '' */
.icon-arrowleft-02:before { content: '\e822'; } /* '' */
.icon-arrowtop-02:before { content: '\e823'; } /* '' */
.icon-logout:before { content: '\e824'; } /* '' */
.icon-categories:before { content: '\e825'; } /* '' */
.icon-rotate:before { content: '\e826'; } /* '' */
.icon-upload:before { content: '\e827'; } /* '' */
.icon-review:before { content: '\e828'; } /* '' */
.icon-to-do:before { content: '\e829'; } /* '' */
.icon-priority:before { content: '\e82a'; } /* '' */
.icon-rated:before { content: '\e82b'; } /* '' */
.icon-paperclip:before { content: '\e82c'; } /* '' */
.icon-expand:before { content: '\e82d'; } /* '' */
.icon-pen:before { content: '\e82e'; } /* '' */
.icon-trash:before { content: '\e82f'; } /* '' */
.icon-share:before { content: '\e830'; } /* '' */
.icon-duplicate:before { content: '\e831'; } /* '' */
.icon-dock:before { content: '\e832'; } /* '' */
.icon-loader:before { content: '\e833'; } /* '' */
.icon-settings:before { content: '\e834'; } /* '' */
.icon-view-block:before { content: '\e835'; } /* '' */
.icon-arrowupleft-1:before { content: '\e836'; } /* '' */
.icon-offline:before { content: '\e837'; } /* '' */
.icon-building:before { content: '\e838'; } /* '' */
.icon-repeating:before { content: '\e839'; } /* '' */
.icon-people-plus:before { content: '\e83a'; } /* '' */
.icon-people:before { content: '\e83b'; } /* '' */
.icon-peoples:before { content: '\e83c'; } /* '' */
.icon-download:before { content: '\e83d'; } /* '' */
.icon-pdf:before { content: '\e83e'; } /* '' */
.icon-print-1:before { content: '\e83f'; } /* '' */
.icon-archive:before { content: '\e840'; } /* '' */
.icon-wow:before { content: '\e841'; } /* '' */
.icon-doc:before { content: '\e842'; } /* '' */
.icon-cloud:before { content: '\e843'; } /* '' */
.icon-order2:before { content: '\e844'; } /* '' */
.icon-order1:before { content: '\e845'; } /* '' */
.icon-filters:before { content: '\e846'; } /* '' */
.icon-mic:before { content: '\e847'; } /* '' */
.icon-load-autocomplete-text:before { content: '\e848'; } /* '' */
.icon-arrowdownleft:before { content: '\e849'; } /* '' */
.icon-cloud-action:before { content: '\e84a'; } /* '' */
.icon-export:before { content: '\e84b'; } /* '' */
.icon-sad-emoji:before { content: '\e84c'; } /* '' */
.icon-view-list:before { content: '\e84d'; } /* '' */
.icon-arrowupleft:before { content: '\e84e'; } /* '' */
.icon-link:before { content: '\e84f'; } /* '' */
.icon-invisible:before { content: '\e850'; } /* '' */
.icon-visible:before { content: '\e851'; } /* '' */
.icon-people-arrow:before { content: '\e852'; } /* '' */
.icon-xls:before { content: '\e853'; } /* '' */
.icon-smiley-emoji:before { content: '\e854'; } /* '' */
.icon-arrowdown:before { content: '\e855'; } /* '' */
.icon-arrowup:before { content: '\e856'; } /* '' */
.icon-assets:before { content: '\e857'; } /* '' */
.icon-equipment:before { content: '\e858'; } /* '' */
.icon-scheduling:before { content: '\e859'; } /* '' */
.icon-location:before { content: '\e85a'; } /* '' */
.icon-locker:before { content: '\e85b'; } /* '' */
.icon-equipment-add:before { content: '\e85c'; } /* '' */
.icon-collaborators:before { content: '\e85d'; } /* '' */
.icon-reload-restart:before { content: '\e85e'; } /* '' */
.icon-density-high-mob:before { content: '\e85f'; } /* '' */
.icon-view-list-density:before { content: '\e860'; } /* '' */
.icon-density-low-mob:before { content: '\e861'; } /* '' */
.icon-flash:before { content: '\e862'; } /* '' */
.icon-checklist:before { content: '\e863'; } /* '' */
.icon-locker-number:before { content: '\e864'; } /* '' */
.icon-plus-camera:before { content: '\e865'; } /* '' */
.icon-arrows-ungroup:before { content: '\e866'; } /* '' */
.icon-loop:before { content: '\e867'; } /* '' */
.icon-barcode:before { content: '\e868'; } /* '' */
.icon-people-arrow-down:before { content: '\e869'; } /* '' */
.icon-sort-drag:before { content: '\e86a'; } /* '' */
.icon-directions:before { content: '\e86b'; } /* '' */
.icon-mail:before { content: '\e86c'; } /* '' */
.icon-phone:before { content: '\e86d'; } /* '' */
.icon-asset-group:before { content: '\e86e'; } /* '' */
.icon-flash-auto:before { content: '\e86f'; } /* '' */
.icon-flash-off:before { content: '\e870'; } /* '' */
.icon-view-tiny-density:before { content: '\e871'; } /* '' */
.icon-csv:before { content: '\e872'; } /* '' */
.icon-stopwatch:before { content: '\e873'; } /* '' */
.icon-hourglass:before { content: '\e874'; } /* '' */
.icon-euro:before { content: '\e875'; } /* '' */
.icon-euro-symbol:before { content: '\e876'; } /* '' */
.icon-break-time:before { content: '\e877'; } /* '' */
.icon-bulb:before { content: '\e878'; } /* '' */
.icon-help-questions:before { content: '\e879'; } /* '' */
.icon-form:before { content: '\e87a'; } /* '' */
.icon-pin:before { content: '\e87b'; } /* '' */
.icon-arrowworkspace:before { content: '\e87c'; } /* '' */
.icon-world:before { content: '\e87d'; } /* '' */
.icon-home:before { content: '\e87e'; } /* '' */
.icon-eml-svg:before { content: '\e87f'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
