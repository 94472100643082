@import '../../styles/colours';

.drop-down-wrapper {
  display: inline-block;
  position: relative;
  text-align: start;
}

.drop-down {
  &__heading {
    color: $deep-gray;
    margin-right: 0.5rem;
  }

  &__elements {
    color: $deep-blue;
    display: inline-block;
    border: 1px solid $gray;
    border-radius: 6px;
    padding: 5px 15px 5px 10px;
    cursor: pointer;
    font-size: 14px;

    span {
      font-weight: 400;
      margin-right: 0.3rem;
      color: $deep-blue;
    }
  }

  &-wrapper {
    width: 65%;

    .gray {
      i {
        color: $status-todo;
      }
      border-color: $status-todo;
    }

    .orange {
      i {
        color: $status-in-progress;
      }
      border-color: $status-in-progress;
    }

    .purple {
      i {
        color: $status-in-review;
      }
      border-color: $status-in-review;
    }

    .green {
      i {
        color: $priority-normal;
      }
      border-color: $priority-normal;
    }

    .red {
      i {
        color: $priority-high;
      }
      border-color: $priority-high;
    }

    .blue {
      i {
        color: $priority-low;
      }
      border-color: $priority-low;
    }

    @media only screen and (max-width: 480px) {
      width: 60%;
    }
  }

  .icon {
    color: $gray;
    margin-right: 0.5rem;
  }
}

.violet {
  .drop-down {
    &__elements {
      color: $violet;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.icon-calendar {
  color: $gray;
  margin-right: 0.5rem;
}

.inactive {
  color: $gray;
}
