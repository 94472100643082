@import '../../styles/colours';

@mixin avatarOffset($left, $z) {
  left: $left;
  z-index: $z;
  position: absolute;
}

@mixin avatarRightOffset($right, $z) {
  left: unset;
  right: $right;
  z-index: $z;
  position: absolute;
}

$avatar-size: 39px;
$avatar-size-sm: 30px;
$status-size: 12px;

.avatar-circle {
  position: relative;
  height: $avatar-size;
  width: $avatar-size;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid $white;
  }

  &-active {
    > img {
      border-color: $confirm;
    }
  }

  &-inactive {
    > img {
      border-color: $inactive;
    }
  }

  .icon-overlay {
    text-align: right;
    position: absolute;
    width: 25%;
    height: 25%;
    right: 3px;
    bottom: 8%;
    border-radius: 50%;
  }

  .active {
    background: $confirm;
  }

  .inactive {
    background: $inactive;
  }

  &.sm {
    height: $avatar-size-sm;
    width: $avatar-size-sm;
  }

  &.first {
    @include avatarRightOffset(100px, 30);
  }

  &.second {
    @include avatarRightOffset(70px, 20);
  }

  &.third {
    @include avatarRightOffset(40px, 10);
  }
}
