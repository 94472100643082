.enlarge {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  transition-duration: 0.3s;
  transition-property: transform;
  &:focus,
  &:hover,
  &:active {
    transform: scale(1.1);
  }
}
